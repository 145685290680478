import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=444cfa2b"
import script from "./auth.vue?vue&type=script&lang=js"
export * from "./auth.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Vinura\\Redem\\redem-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('444cfa2b')) {
      api.createRecord('444cfa2b', component.options)
    } else {
      api.reload('444cfa2b', component.options)
    }
    module.hot.accept("./auth.vue?vue&type=template&id=444cfa2b", function () {
      api.rerender('444cfa2b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/layouts/auth.vue"
export default component.exports